@media screen and (max-width: 700px) {
  .video-center {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  button>img {
    width: 128px;
  }

  .upload-img {
    display: none;
  }

  .contact-container {
    padding: 0 !important;
  }

  .tnc-form {
    max-width: none !important;
  }

  .select-col-small {
    border-top: 1px solid;
    margin-top: 1em;
  }

  .dzu-inputLabel {
    position: relative;
  }

  .upload-img-purchase {
    display: none;
  }

  .upload-img-purchase-col {
    display: none;
  }

  .extend-img {
    width: inherit;
  }

  .avatar-col {
    flex: unset;
  }
}


.App {
  text-align: center;
  background-color: #161d2c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.accordion-body {
  background-color: #161d2c;
  font-size: 0.6em;
  color: white;
}

.pre-scrollable {
  max-height: 340px;
  text-align: left;
}

h3 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.nav-pills .nav-link {
  background: white;
}

.nav-row {
  align-items: flex-start;
}

.video-center {
  width: 200px;
}

.upload-img {
  height: 50%;
}

.upload-img-purchase {
  height: 75%;
}

.muted-text {
  font-weight: 200;
  text-align: left;
}

.learn-more {
  border-top: 1px solid;
  font-weight: 100;
  font-size: smaller;
  padding-top: 1%;
}

ul>li {
  list-style-position: inside;
}

.mv-logo {
  width: 100%;
}

.decentraland-select-btn {
  display: contents;
}

.logo {
  display: flex;
  flex: 1 1 0%;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.connect-wallet {
  display: flex;
  flex: 1 1 0%;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.avatar-preview-img {
  width: inherit;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  border-width: 4px;
  border-radius: 4px;
  border-color: #eeeeee;
  border-style: dashed;
  outline: none;
  transition: border .24s ease-in-out;
}

.dzu-inputLabel {
  color: white;
}

.dzu-dropzone {
  overflow: hidden;
  border: solid;
}

.dzu-previewContainer {
  border: none;
}

.dzu-previewImage {
  max-height: 10%;
}

.tnc-form {
  margin-top: 2rem;
  max-width: 40%;
}

.header {
  margin-bottom: 3rem;
}

.header-purchase {
  margin-bottom: 2rem;
}

.helpful-links {
  display: inline-block;
  padding-left: 0;
  text-align: left;
}

.progress {
  padding-top: 2em;
  background-color: unset;
  height: unset;
}


/* stuff from nftplazas.com */

/* social */

.sc_layouts_row_type_narrow .socials_wrap .social_item .social_icon,
.scheme_default.sc_layouts_row_type_narrow .socials_wrap .social_item .social_icon {
  background-color: transparent;
  color: #141924
}

.scheme_default .sc_layouts_row_type_narrow .socials_wrap .social_item:hover .social_icon,
.scheme_default.sc_layouts_row_type_narrow .socials_wrap .social_item:hover .social_icon {
  background-color: transparent;
  color: #c6dd28
}

.contacts_socials a {
  color: #ffffff;
}

/* widget_contacts */
.widget_contacts .contacts_description {
  font-weight: 300;
  font-size: 15px;
}

.widget_contacts .socials_wrap .social_item .social_icon {
  background: transparent !important;
}

.widget_contacts .socials_wrap .social_item .social_icon {
  margin: 0.2em;
  line-height: 2.5em;
  font-size: 3em;
}

.widget_contacts .socials_wrap .social_item {
  margin: 6px 6px 0 0;
}

.widget_contacts .contacts_logo+.contacts_socials,
.widget_contacts .contacts_info+.contacts_socials,
.widget_contacts .contacts_description+.contacts_socials {
  margin-top: 1.5em;
}


/* HOVER ANIMATE BUTTON */
.sc_button {
  position: relative;
  z-index: 1;
  color: #141924;
  border-color: #141924;
  background-color: white;
}

.sc_button .sc_button_icon,
.sc_button .sc_button_text {
  position: relative;
}

.sc_button:before {
  content: '';
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background: #141924;
}

.sc_button:hover:before {
  width: 100%;
}

.dzu-submitButton {
  border-radius: 0%;
  color: #ffffff;
  padding: 5px 10px;
  color: #141924;
  border-color: #141924;
  background-color: white;
}

.dzu-submitButton:disabled,
.dzu-submitButton[disabled] {
  background-color: #363636;
  color: #636363;
}

.dzu-previewButton {
  height: 50px;
  width: 50px;
  background-size: 30px;
  background-color: white;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktdHJhc2giIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHBhdGggZD0iTTUuNSA1LjVBLjUuNSAwIDAgMSA2IDZ2NmEuNS41IDAgMCAxLTEgMFY2YS41LjUgMCAwIDEgLjUtLjV6bTIuNSAwYS41LjUgMCAwIDEgLjUuNXY2YS41LjUgMCAwIDEtMSAwVjZhLjUuNSAwIDAgMSAuNS0uNXptMyAuNWEuNS41IDAgMCAwLTEgMHY2YS41LjUgMCAwIDAgMSAwVjZ6Ii8+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTQuNSAzYTEgMSAwIDAgMS0xIDFIMTN2OWEyIDIgMCAwIDEtMiAySDVhMiAyIDAgMCAxLTItMlY0aC0uNWExIDEgMCAwIDEtMS0xVjJhMSAxIDAgMCAxIDEtMUg2YTEgMSAwIDAgMSAxLTFoMmExIDEgMCAwIDEgMSAxaDMuNWExIDEgMCAwIDEgMSAxdjF6TTQuMTE4IDQgNCA0LjA1OVYxM2ExIDEgMCAwIDAgMSAxaDZhMSAxIDAgMCAwIDEtMVY0LjA1OUwxMS44ODIgNEg0LjExOHpNMi41IDNWMmgxMXYxaC0xMXoiLz4KPC9zdmc+") !important;
}